<template>
    <section id="workingArea">
        <div class="text-white subBlock" >
            <h4 class="text-center q-mb-lg">$STAR | Exclusive Access</h4>
            <p v-if="!isMetaMaskConnected" class="q-mt-lg event-message text-center">
                Please connect wallet to check the status.
            </p>
            <p v-if="isMetaMaskConnected && resultPeriodEndDate != '0'" 
                class="text-center event-message q-mt-lg">
                Ended. First 25% of both is unlocked on 18th August 6AM UTC.
            </p>
            <p v-if="isMetaMaskConnected && resultPeriodEndDate == '0'" 
                class="text-center event-message q-mt-lg">
                Ended. First 25% of both is unlocked on 18th August 6AM UTC.
            </p>
            <div class="justify-center flex items-center q-mt-lg q-pb-sm">
                <div class="q-px-lg q-pb-md">
                    <div class="justify-center flex items-center q-my-sm sol">
                        <div>
                            <span class="avatar">
                                <img :src="require(`@/assets/images/tokens/star.png`)" />
                            </span>
                        </div>
                        <div class="justify-center flex items-baseline">
                            <h3 class="q-my-none" style="margin: 0 5px">
                            <div>NONE</div>
                            </h3>
                            
                        </div>
                    </div>
                    <p class="text-center label_lg">Available $STAR</p>
                </div>
                <div class="q-px-lg q-pb-md">
                    <div class="justify-center flex items-center q-my-sm sol">
                        <div>
                            <span class="avatar">
                                <img :src="require(`@/assets/images/tokens/star.png`)" />
                            </span>
                        </div>
                        <div class="justify-center flex items-baseline">
                            <h3 class="q-my-none" style="margin:0 5px">☆</h3>
                            <div class="token">ASTR</div>
                        </div>
                    </div>
                    <p class="text-center label_lg">$STAR Token Price</p>
                </div>
                <div class="q-px-lg q-pb-md">
                    <div class="justify-center flex items-center q-my-sm sol">
                        <div>
                            <span class="avatar">
                                <img :src="require(`@/assets/images/tokens/astr.png`)"/>
                            </span>
                        </div>
                        <div class="justify-center flex items-baseline">
                            <h3 class="q-my-none" style="margin:0 5px">
                                <div>{{userSharedASTR}}</div>
                            </h3>
                            <div class="token">ASTR</div>
                        </div>
                    </div>
                    <p class="text-center label_lg">ASTR You Sent</p>
                </div>
            </div>
        </div>
        <div class="subBlock">
            <p v-if="isMetaMaskConnected" class="text-center q-my-lg">
                <span class="event-message">Vesting Period : {{resultBeginDate}} ~ {{resultEndDate}}</span>
            </p>
            <div>
                <h6 class="q-my-lg q-ml-sm text-center" style="color: #ace3e5;">$STAR Tokens Acquired</h6>
            </div>
            <div class="justify-center flex items-center sol">
                <div class="q-px-lg">
                    <div class="justify-center flex items-center q-my-sm text-center">
                        <h3 class="q-my-none">
                            <span class="label_lg">Total</span><br><br>
                            <span>{{userTotalSharedSTAR}}</span>
                            <span class="q-pl-sm token">$STAR</span>
                        </h3>
                    </div>
                </div>
                <div class="q-px-lg text-center">
                    <div class="justify-center flex items-center q-my-sm">
                        <h3 class="q-my-none">
                            <span class="label_lg">Claimable</span><br><br>
                            <span>{{userClaimableSTAR}}</span>
                            <span class="q-pl-sm token">$STAR</span>
                        </h3>
                    </div>
                </div>
                <div class="q-px-lg text-center">
                    <div class="justify-center flex items-center q-my-sm">
                        <h3 class="q-my-none">
                            <span class="label_lg">Claimed</span><br><br>
                            <span>{{userClaimedSTAR}}</span>
                            <span class="q-pl-sm token">$STAR</span>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="justify-center flex items-center">
                <div v-if="isMetaMaskConnected" class="mintBtn q-my-lg half" @click="claim">Claim</div>
            </div>
        </div>
    </section>
</template>

<script>
import BigNumber from 'bignumber.js'
    export default {
        components: {},
        data() {
            return {
            }
        },
        computed: {
            isMetaMaskConnected() {
                return this.$store.state.account!=null;
            },
            resultBeginDate() {
                let date = this.$store.state.exchanger.vestingBegin - 120 * 1000;
                const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
                const dtf = new Intl.DateTimeFormat('en-US', options);
                return dtf.format(date);
            },
            resultEndDate() {
                let date = this.$store.state.exchanger.vestingEnd - 120 * 1000;
                const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
                const dtf = new Intl.DateTimeFormat('en-US', options);
                return dtf.format(date);
            },
            resultPeriodEndDate() {
                let enddate = this.$store.state.exchanger.periodEnd - 120 * 1000;

                let currentTime = Date.now();
                var difference = enddate - currentTime;

                if (difference < 0)
                    return "0"; 

                var daysDifference = Math.floor(difference/1000/60/60/24);
                difference -= daysDifference*1000*60*60*24;

                var hoursDifference = Math.floor(difference/1000/60/60);
                difference -= hoursDifference*1000*60*60;

                var minutesDifference = Math.floor(difference/1000/60);
                difference -= minutesDifference*1000*60;

                var result = daysDifference.toString() + " days " +  hoursDifference.toString() + " hours " + minutesDifference.toString() + " minutes";

                return result;
            },
            userSharedASTR() {
                if (this.$store.state.exchanger.recipientInfo != null && 
                    this.$store.state.exchanger.recipientInfo[0] > 0)
                        return this.getDecimalData(BigNumber(this.$store.state.exchanger.recipientInfo[0])
                            .shiftedBy(-18));

                return '0.00'
            },
            userTotalSharedSTAR() {
                if (this.$store.state.exchangerEx.totalRewards > 0)
                        return this.getDecimalData(BigNumber(this.$store.state.exchangerEx.totalRewards)
                                .shiftedBy(-18));

                return '0.00'
            },
            userClaimableSTAR() {
                if(this.$store.state.exchangerEx.claimableRewards > 0)
                    return this.getDecimalData(BigNumber(this.$store.state.exchangerEx.claimableRewards)
                            .shiftedBy(-18));
                else
                    return '0.00'
            },
            userClaimedSTAR() {
                if (this.$store.state.exchangerEx.claimedRewards > 0)
                        return this.getDecimalData(BigNumber(this.$store.state.exchangerEx.claimedRewards)
                            .shiftedBy(-18));
                            
                return '0.00'
            },
        },
        mounted() {
            this.$store.commit('read_exchanger');
        },
        methods: {
            connectWallet() {                
                this.$store.dispatch('connect');              
            },
            claim() {
                this.$store.dispatch('claimSTARInExchangerEx');
            },
            getDecimalData(value) {
                let limit_xxx = BigNumber(1000);
                let limit_xx = BigNumber(1);
                let limit_x = BigNumber(1).shiftedBy(-3);
                let limit_sm = BigNumber(1).shiftedBy(-6);
                let limit_md = BigNumber(1).shiftedBy(-9);
                let limit_lg = BigNumber(1).shiftedBy(-12);

                if (limit_lg.isGreaterThan(value)) {
                    return value.toString();
                }
                else if (limit_md.isGreaterThan(value)) {
                    return value.decimalPlaces(12, 1).toString();
                }
                else if (limit_sm.isGreaterThan(value)) {
                    return value.decimalPlaces(9, 1).toString();
                }
                else if (limit_x.isGreaterThan(value)) {
                    return value.decimalPlaces(6, 1).toString();
                }
                else if (limit_xx.isGreaterThan(value)) {
                    return value.decimalPlaces(4, 1).toString();
                }
                else if (limit_xxx.isGreaterThan(value)) {
                    return value.decimalPlaces(2, 1).toString();
                }
                else {
                    return value.decimalPlaces(0, 1).toString();
                }
            }
        }
    }
</script>

<style>
    .claim {
        /* width:50%;  */
        margin:40px auto;
    }

    hr {
        border-color: hsl(0deg 0% 100% / 12%) !important;
    }

    .explorer div.row, .explorer div.col {
        margin:0 !important;
        padding:0 !important;
    }

    .subBlock {
        background-color: #42a1e821; 
        border-radius: 10px; 
        width:60%; 
        padding:30px; 
        margin:20px auto;
    }

    @media(max-width: 996px) {
        .subBlock {
            width:100%; 
        }

        .label1 {
            float:left;
            text-align: left;
        }
    }
</style>
